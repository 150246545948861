<template>
  <div class="container">
    <el-table :data="tableData" style="width: 100%" border>
      <el-table-column label="序号" type="index" width="55" align="center">
      </el-table-column>
      <el-table-column label="日期" prop="date" align="center">
        <template slot-scope="scope">
          <span v-show="scope.row.isShow">{{ scope.row.date }}</span>
          <el-input
            type="text"
            :placeholder="scope.row.date"
            v-model="scope.row.date"
            v-show="!scope.row.isShow"
          />
        </template>
      </el-table-column>
      <el-table-column label="姓名" prop="name" align="center">
        <template slot-scope="scope">
          <span v-show="scope.row.isShow">{{ scope.row.name }}</span>
          <el-input
            type="text"
            :placeholder="scope.row.name"
            v-model="scope.row.name"
            v-show="!scope.row.isShow"
          />
        </template>
      </el-table-column>
      <el-table-column label="地址" prop="address" align="center">
        <template slot-scope="scope">
          <span v-show="scope.row.isShow">{{ scope.row.address }}</span>
          <el-input
            type="text"
            :placeholder="scope.row.address"
            v-model="scope.row.address"
            v-show="!scope.row.isShow"
          />
        </template>
      </el-table-column>
      <el-table-column align="right">
        <template slot-scope="scope">
          <el-button
            size="mini"
            @click="handleEdit(scope.$index, scope.row)"
            v-show="scope.row.isShow"
            >编辑</el-button
          >
          <el-button
            @click="hold(scope.$index, scope.row)"
            size="mini"
            v-show="!scope.row.isShow"
            >保存</el-button
          >
          <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div @click="addLine()" style="cursor: pointer; color: #409eff">
      添加一行
    </div>
  </div>
</template>
 
<script>
export default {
  data() {
    return {
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
          isShow: true,
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1517 弄",
          isShow: true,
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1519 弄",
          isShow: true,
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1516 弄",
          isShow: true,
        },
      ],
    };
  },
  methods: {
    /* 编辑 */
    handleEdit(index, row) {
      console.log(index, row);
      row.isShow = false;
    },
    /* 保存 */
    hold(index, row) {
      row.isShow = true;
    },
    /* 删除 */
    handleDelete(index, row) {
      console.log(index, row);
      this.tableData.splice(index, 1);
    },
    /* 添加新的一行 (默认是可编辑状态)*/
    addLine() {
      let obj = {
        date: "",
        name: "",
        address: "",
        isShow: false,
      };
      this.tableData.push(obj);
    },
  },
};
</script>
 
<style scoped>
/* 除去input的边框 */
/* ::v-deep .el-input__inner {
  border: none !important;
} */
</style>
